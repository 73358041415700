import { Theme } from "@mui/system";
import { useMemo, useState, useEffect } from "react";

export function useIsMobile() {
  return useMemo(() => navigator.userAgent.includes("Mobile"), []);
}

export function useIsDarkTheme(theme: Theme) {
  return useMemo(() => theme.palette.mode === "dark", [theme.palette.mode]);
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}
