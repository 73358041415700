import { lazy, LazyExoticComponent } from "react";

type PageDefinition = {
  title: string;
  path: string;
  explizitPath?: string;
  Element: LazyExoticComponent<() => JSX.Element>;
};

const NAVBAR_EXCLUDES = ["imprint"];

export const pages: {
  [key: string]: PageDefinition;
} = {
  // resume: {
  //   title: "Résumé",
  //   path: "resume",
  //   Element: lazy(() => import("./pages/Resume")),
  // },
  about: {
    title: "About Me",
    path: "*",
    explizitPath: "about",
    Element: lazy(() => import("./pages/About")),
  },
  projects: {
    title: "Projects / Achievements",
    path: "projects-and-achievements",
    Element: lazy(() => import("./pages/Projects")),
  },
  imprint: {
    title: "Imprint (Impressum)",
    path: "imprint",
    Element: lazy(() => import("./pages/Imprint")),
  },
};

export const filteredPages: {
  [key: string]: PageDefinition;
} = Object.entries(pages)
  .filter(([_, { path }]) => !NAVBAR_EXCLUDES.includes(path))
  .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {});
