import { lazy, Suspense, useMemo, useState, createContext } from "react";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { darken } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import useMediaQuery from "@mui/material/useMediaQuery";
import { pages } from "./Routing";
import { lighten } from "@mui/system";
import { useIsDarkTheme, useIsMobile } from "./misc/hooks";
import bgLight from "./media/bg-light.png";
import bgDark from "./media/bg-dark.png";
const BasicInformation = lazy(() => import("./components/BasicInformation"));
const Navbar = lazy(() => import("./components/Navbar"));
const CssBaseline = lazy(() => import("@mui/material/CssBaseline"));
const Box = lazy(() => import("@mui/material/Box"));
const GlobalStyles = lazy(() => import("@mui/material/GlobalStyles"));

export const ColorModeContext = createContext({
  toggleColorMode: () => {},
});

function App() {
  const [mode, setMode] = useState<"light" | "dark">(
    useMediaQuery("(prefers-color-scheme: dark)") ? "dark" : "light"
  );

  const isMobile = useIsMobile();

  const theme = useMemo(
    () =>
      createTheme({
        //...baseTheme,
        palette: {
          mode,
        },
      }),
    [mode]
  );

  const layoutBreak = useMediaQuery("(max-width: 1529px)");

  const isDarkTheme = useIsDarkTheme(theme);

  const globalStyle = useMemo(
    () => ({
      "::-webkit-scrollbar": {
        width: "15px",
      },
      "::-webkit-scrollbar-track": {
        backgroundColor: "transparent",
      },
      "::-webkit-scrollbar-thumb": {
        backgroundColor: isDarkTheme
          ? darken(theme.palette.text.primary, 0.4)
          : lighten(theme.palette.text.primary, 0.4),
        borderRadius: "15px",
        border: "4px solid transparent",
        backgroundClip: "content-box",
      },
      "::-webkit-scrollbar-thumb:hover": {
        backgroundColor: isDarkTheme
          ? darken(theme.palette.text.primary, 0.2)
          : lighten(theme.palette.text.primary, 0.2),
      },
      body: {
        backgroundImage: `url(${isDarkTheme ? bgDark : bgLight})`,
      },
    }),
    [theme.palette.text.primary, isDarkTheme]
  );

  return (
    <ColorModeContext.Provider
      value={{
        toggleColorMode: () =>
          setMode((prevMode) => (prevMode === "light" ? "dark" : "light")),
      }}
    >
      <ThemeProvider theme={theme}>
        <GlobalStyles styles={isMobile ? undefined : globalStyle} />
        <BrowserRouter>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "100vh",
              width: "100vw",
            }}
          >
            <CssBaseline />
            <Navbar />
            <div
              style={{
                height: "100%",
                display: "flex",
                flexWrap: "wrap",
                overflow: "auto",
                flexDirection: "row-reverse",
              }}
              id="main-content"
            >
              {layoutBreak ? null : <div style={{ flexGrow: 1 }} />}
              <div
                style={{
                  justifySelf: "center",
                  padding: 0,
                  display: "flex",
                  flexDirection: layoutBreak ? "row" : "column",
                  flexGrow: 1,
                  width: layoutBreak ? undefined : "60vw",
                }}
              >
                <Routes>
                  {Object.values(pages).map(({ path, Element, title }) => (
                    <Route
                      key={title}
                      path={path}
                      element={
                        <Suspense
                          fallback={
                            <div
                              style={{
                                flexGrow: 1,
                                padding: "auto",
                                alignSelf: "center",
                                display: "flex",
                                top: "50%",
                                position: "absolute",
                              }}
                            >
                              <CircularProgress />
                            </div>
                          }
                          children={<Element />}
                        ></Suspense>
                      }
                    />
                  ))}
                </Routes>
              </div>
              {layoutBreak ? null : <div style={{ flexGrow: 1 }} />}
              <Box
                sx={
                  layoutBreak
                    ? { margin: "auto" }
                    : {
                        pt: 24,
                        width: 400,
                        marginLeft: 0,
                        left: 0,
                      }
                }
              >
                <BasicInformation compact={layoutBreak} />
              </Box>
            </div>
          </div>
        </BrowserRouter>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
