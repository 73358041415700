/**
 * Why? Because I can...
 */

const crazyStyle =
  "font-weight: bold; font-size: 50px;color: red; text-shadow: 3px 3px 0 rgb(217,31,38) , 6px 6px 0 rgb(226,91,14) , 9px 9px 0 rgb(245,221,8) , 12px 12px 0 rgb(5,148,68) , 15px 15px 0 rgb(2,135,206) , 18px 18px 0 rgb(4,77,145) , 21px 21px 0 rgb(42,21,113)" as const;

const defaultTextStyle = "color: rgb(2,135,206); font-size: 15px" as const;

const divider = `%c ${"#".repeat(14)} `;

console.info(divider, crazyStyle);

console.info("%c\tHi 😊👋\t", crazyStyle);

console.info(
  "%cWhat are you looking for? Here is nothing to see.",
  defaultTextStyle
);

console.info(
  "%cFeel free to visit my GitHub: https://github.com/flokol120",
  defaultTextStyle
);

console.info(divider, crazyStyle);

export {};
